.rd-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .rd-mb-header {
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
            width: 150px;
        }
        
    h2 {
        color: var(--text-color);
    }
}

.rd-goto-app {
    padding: 10px 30px;
    background-color: var( --btn-primary-color);
    color: white;
    font-size: 16px;
    border-radius: 30px;
    font-weight: 500;
    margin-top: 100px;

    
}
}