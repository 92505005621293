.fileList-item-container{
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 14px;
    border: 1px solid var(--border-color);
    width: 240px;
    border-radius: 10px;
    cursor: pointer;
    position: relative;

    &:hover {
        border: 1px solid var( --border-hover-color);
    }

    &:hover .file-option{
        display: block;
    }

    .file-icon {
        padding: 4px;
        display: flex;
        align-items: center;
    }

    .file-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        max-width: 130px;
        // overflow: hidden;
        p{
            margin: 0;
            line-height: 14px;
            font-weight: 500;
            text-overflow: ellipsis;
            max-width: 130px;
            white-space: nowrap;
            overflow: hidden;
            
        }

        span {
            font-size: 12px;
            opacity: 0.8;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

        }
    }

    .file-option {
        align-items: center;
        height: 26px;
        border-radius: 4px;
        padding: 0 4px;
        font-size: 20px;
        color: var(--text-color);
        position: relative;
        display: none;

   

        &:hover {
            background-color:var(--detail-button-hover-color);
        }

        
    }

    .option-popup {
        position: absolute;
        border-radius: 4px;
        border: 1px solid var(--border-color);
        z-index: 999;
        background-color: white;
        display: flex;
        align-items: center;
        right: 0;
        flex-direction: column;
        top: 50%;
        transform: translateY(25%);
        right: 2px;

        span {
            color: #c92e54;
            font-size: 14px;
            padding: 4px 10px;
            display: block;
            width: 100%;

            &:hover {
                background-color: var(--footer-bg-color);
            }
        }
    }
}

.img-option-popup {
    position: absolute;
    border-radius: 4px;
    border: 1px solid var(--border-color);
    z-index: 999;
    background-color: white;
    display: flex;
    align-items: center;
    right: 0;
    flex-direction: column;
    top: 50%;
    transform: translateY(25%);
    right: 2px;
    width: 73px;

    span {
        color: #c92e54;
        font-size: 14px;
        padding: 4px 10px;
        display: block;
        width: 100%;

        &:hover {
            background-color: var(--footer-bg-color);
        }
    }
}

.img-thumnail-container {
    height: 54px;
    width: 54px;
    border-radius: 6px;
    border: 1px solid var(--border-color);
    cursor: pointer;
    position: relative;

    &:hover .image-option {
        background-color: rgba(29, 29, 29, 0.452);
        display: block;

    }


    img {
        height: 54px;
        width: 54px;
        object-fit: cover;
        border-radius: 6px;

    }

    .image-option {
        position: absolute;
        top: 18px;
        right: 0;
        color: white;
        font-size: 25px;
        padding: 6px;
        border-radius: 6px;
    }

}