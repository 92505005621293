.member {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.member_list {
  margin-bottom: 0;
  margin-top: 50px;
  width: 725px;
}
.member_list_section {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  padding: 8px 0;
  position: relative;
}
.member_list_title {
  margin-right: auto;
}
.member_title {
  font-size: 16px;
  line-height: 20px;
  margin: 0;
}
.teamMemberSection {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
  justify-content: space-between;
  margin-top: 4px;
  /* height: 218px;
  overflow: hidden; */
}
.themeMemberCard {
  display: flex;
  align-items: center;
  padding: 8px;
  border-color: transparent;
  border: 1px solid #c9c9c9;
  border-radius: 10px;
  /* fix */
  /* width: 150px; */
}
.themeMemberCard:hover {
  background-color: #e3e3e3;
}
.themeMemberCard_addMember {
  align-items: center;
  border: 1px dashed #6d6e6f;
  border-radius: 50px;
  display: flex;
  fill: #6d6e6f;
  flex-grow: 0;
  flex-shrink: 0;
  height: 33px;
  justify-content: center;
  margin: 8px;
  width: 33px;
}
.Icon {
  width: 16px;
  height: 16px;
}
.teamOverviewMemberSection {
  align-items: center;
  display: flex;
  overflow: hidden;
}
.teamOverviewMemberSection_addmember {
  font-size: 14px;
  line-height: 20px;
  margin: 0;
}
.teamOverviewMemberSection_name {
  font-weight: 500;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.teamProjectSection_hubList {
  margin-top: 4px;
}
.sortableList_itemContainer_colum {
  flex-direction: column;
}
.sortableList_itemContainer {
  display: flex;
  height: 100%;
  width: 100%;
}
.teamProjectSection_itemRow {
  margin: 0 -48px;
}
.RowStructure {
  position: relative;
}
.RowStructure ::after {
  border-bottom: 1px solid #d9d9d9;
  content: "";
  display: block;
  left: 48px;
  pointer-events: none;
  position: absolute;
  right: 48px;
  bottom: -2px;
  display: none;
}
.ItemRowTwoColumStructure_left {
  align-items: center;
  display: flex;
  flex: 1 1000 auto;
  height: 100%;
  justify-content: flex-start;
  min-width: 1px;
  position: relative;
  margin: 0 11px;
}

.RowStructure_leftGutter {
  width: 48px;
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: 100%;
  justify-content: center;
  opacity: 0;
}
.RowStructure_leftChildren {
  margin-left: -14px;
  /* margin-right: 16px; */
  min-width: 1px;
  display: flex;
  margin-bottom: 4px;
  margin-top: 4px;
}
.IconQquircleRow_leftElements {
  cursor: pointer;
  align-items: center;
  box-sizing: border-box;
  color: #6d6e6f;
  display: inline-flex;
  text-align: center;
  /* border: 1px solid #626262; */
  padding: 5px;
  border-radius: 10px;
}
.ItemRowTwoColumStructure_left:hover {
  background-color: #d1d1d1;
  border-radius: 18px;
  cursor: pointer;
}
.IconSquircleRow_box {
  border-radius: 12px;
  height: 48px;
  width: 48px;
  margin: 4px 16px 4px 0;
  align-items: center;
  border: 2px dashed #6d6e6f;
  box-sizing: border-box;
  color: #6d6e6f;
  display: flex;
  fill: #6d6e6f;
  justify-content: center;
  position: relative;
}
.member_title_templates {
  width: 160px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.large_font {
  font-size: 19px;
}
.top_20 {
  position: relative;
  top: -2px;
  padding-left: 10px;
}
.active {
  animation: like 0.5s 1;
  fill: #faad14;
  stroke: none;
}
.HeartOutlined {
}

@-webkit-keyframes like {
  0% {
    transform: scale(1);
  }
  90% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1.1);
  }
}

.themeMemberCard {
  padding: 2px;
}
.projects-zone {
  margin-top: 15px;
}
.member_title {
  font-weight: 600;
}
