.taskname-container{
    width: 100%;
    padding: 10px 24px 10px 10px;

    textarea{
        width: 100%;
        padding: 8px 14px;
        font-size: 28px;
        line-height: 32px;
        border-radius:6px ;
        resize: none;
        border: none;
        border: 2px solid transparent;;
        outline: none;

        &:hover{
            border: 2px solid var(--detail-border-color);
        }

        &:focus {
            border: 2px solid var( --btn-primary-color);;
        }
        
        &::-webkit-scrollbar{
            width: 0;
        }
    }
}