.dt-comment-container {

    .comment-header {
        background-color: var(--footer-bg-color);
        padding: 0px 24px 0px 36px;

        .cmt-header-content {
            display: flex;
            gap: 10px;
            align-items: center;
            padding: 10px 0;


            img {
                height: 32px;
                width: 32px;
                border-radius: 50%;
            }

            .cmt-activity {
                font-weight: 500;
                font-size: 17;
                color: black;
            }

            span {
                color: var(--btn-primary-color);
                &:hover {
                    text-decoration: underline;
                    cursor: pointer;
                }
                margin-left: 42px;
            }

            .task-created-time {
                font-size: 12px;
                color: var(--text-color);
                display: inline;
                margin-left: 10px;
            }
        }

        .cmt-loadmore {
            color: var(--btn-primary-color);

            padding-left: 42px;
            padding-bottom: 10px;
            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }

            border-bottom: 1px solid var(--border-color);

        }
    }

}