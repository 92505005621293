.mytask-container{
    background-color: var(--white-color);
    height: 100vh;
    margin: -24px;
    padding: 0 24px;
}

.mt-header-container{
    position: fixed;
    right: 0;
    left: 0;
    top: 64px;
    // z-index: 99;
    transition: ease 0.2s;
}


.mytask-header{
    padding: 0 24px;
    background-color: var(--white-color);
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    

    .mytask-header-items{
        display: flex;
        gap: 20px;
        align-items: center;

        .mytask-header-item{
            display: flex;
            gap: 6px;
            align-items: center;
            color: var(--text-color);
            font-weight: 600;
            padding: 2px 8px;
            border-radius: 4px;
            cursor: pointer;

            &:hover {
                background-color: var( --text-hover-background-color );
            }
            
        }
    }
}

.mytask-content-container{
    transition: ease 0.2s;
    right: 0;
    padding: 0 24px;
    position: fixed;
    left: 0;
    // width: 50%;
    bottom: 0;
    overflow-y: auto;
    overflow-x: hidden;
    top: calc(var( --session-row-height) + var(--page-header-height) + var(--my-task-header-height) );
}

.hasDetail {
    padding: 0  0 0 24px;

    width: 45%;
}

.mytask-loading {
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: center;

}