.add-section-container {

    .add-section-input {
        display: flex;
        gap: 15px;
        align-items: center;
        padding: 7px;

        input {
            border: 2px solid var(--btn-primary-color);
            outline: none;
            font-size: 16px;
            font-weight: 500;
            border-radius: 6px;
            padding: 5px;
        }
    }

    .add-section {
        display: flex;
        align-items: center;
        gap: 15px;
        cursor: pointer;
        padding: 5px 10px;
        width: fit-content;
        border-radius: 4px;

        &:hover {
            background-color: var(--text-hover-background-color);
        }

        div {
            font-size: 16px;
            font-weight: 500;
            opacity: 0.8;
        }
    }
}