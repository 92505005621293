.footer-editor-container {
    border: 1px solid var(--border-hover-color);
    width: 100%;
    border-radius: 6px;
    padding: 4px 10px;
    transition: ease 0.2s;
    overflow: hidden;
    background-color: var(--white-color);
    position: relative;


    .footer-editor-wapper {
        overflow-y: auto;
        width: 100%;
        transition: ease 0.3s;

        .public-DraftStyleDefault-block.public-DraftStyleDefault-ltr {
            padding-top: 0 !important;
        }
        .DraftEditor-root {
        transition: ease 0.2s;

            min-height: 88px;
            .DraftEditor-editorContainer {
            min-height: 88px;

            .notranslate.public-DraftEditor-content {
            min-height: 88px;

            }
            }

        }
    
    }

    .footerEdit-toolbar{
        height: 52px;
        width: 100%;
        padding: 0 10px;
        display: flex;
        align-items: center;
        background-color: white;
      
        justify-content: space-between;
        position: absolute;
        bottom: 0;
        left: 0;
        .ft-toolbar-icons {
            display: flex;
            gap: 10px;

            
        }

        .ft-toolbar-submit {
            display: flex;
            gap: 10px;
            align-items: center;

            button {
                padding: 6px 8px;
                border-radius: 6px;
                border: none;
                background-color: var(--btn-primary-color);
                color: white;
                font-weight: 500;
                cursor: pointer;

                &:hover {
                    background-color: var(--btn-primary-color-hover);
                }
            }

            .cancel-btn {
                background-color: white;
                color: black;
                border: 1px solid var(--detail-border-color);

                &:hover {
                    background-color: var(--detail-button-hover-color);
                }
            }
        }


        .textEdit-icon {
            font-size: 26px;
            border-radius: 6px;
            padding: 4px;
            opacity: 0.8;

         

            &:hover {
                background-color: var(--detail-button-hover-color);
                color: black;
                cursor: pointer;
            }
        }

        .active{
            color: var( --btn-primary-color);
            background-color: var(--textedit-active-bgcolor);
        }
    }
}

.footer-editor-container .public-DraftStyleDefault-block {
    margin: 0;
  }
  
  .file{
    height: 64px;
    display: flex;
    max-width: 100%;
    flex-wrap: wrap;
    gap: 5px;
    cursor: text;
  }




.mentionSuggestionsPopup {
    background-color: aqua;
}
#areadropfile{
    width: 100%;
    height: 100%;
    position: absolute;
    margin-left: 50px;
    margin-top: 20px;
    z-index: 1;
    color: 'black';
    font-weight: bold;
    font-size: 18px;
}






 