
  
  .bodysearch{
    background-color: #fff;
  }
  .container_circle{
    width: 40px;
    margin: 70px auto;
    width: 35px;
    margin: 3px auto;
    margin-bottom: 5px;
  }
  .dot{
    width: 10px;
    height: 10px;
    display: inline-block;
    border-radius: 100%;
  }
  .dot1{
    background-color: #1abc9c;
    animation: jump-up 0.6s 0.1s linear infinite;
  }
  .dot2{
    background-color: #ffd64a;
    animation: jump-up 0.6s 0.2s linear infinite;
  }
  .dot3{
    background-color: #e867af;
    animation: jump-up 0.6s 0.3s linear infinite;
  }
  @keyframes jump-up{
    50%{
      transform: translate(0,15px);
    }
  }

  