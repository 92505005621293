.ProjectList-container {
    // height: inherit;
    // width: inherit;
    // margin-top: -12px;
    display: flex;
    align-items: center;
    width: 100%;

 

      
  

}

.ProjectList-seclect {

    .ant-select-selector {

        border: none !important;
        background-color: transparent !important;

        .ant-select-selection-overflow {
            flex-wrap: nowrap;
            overflow-x: hidden;
            overflow-y: hidden;
       
        }
      
    }
}

// .container {
//     -ms-overflow-style: none;  / Internet Explorer 10+ /
//     scrollbar-width: none;  / Firefox /
// }
// .container::-webkit-scrollbar { 
//     display: none;  / Safari and Chrome /
// }