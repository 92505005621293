.header_home_right{
  position: relative;
  left: 55%;
  margin-bottom: 10px;
  width: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 7px;
}
.header_home_right input{
  /* width: 500px;
  border-radius: 15px;
  border: 1px solid #a39b9b ; */
  
}
.header_home_icon{
  position: absolute;
  top: 32%;
  left: 6%;
}
.ant-layout-header{
  line-height: normal;
}
.header_home_task {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 20px;
  background-color: coral;
}
.header_home_task a{
  position: absolute;
  /* left: 10px;
  top: 4px; */
  color: white;
}
.header_home_user{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: hotpink;
  left: 95%
}
.header_home_Team_user_project{
  
}
.header_home_user a{
  color: white;
}

.header_home_Team_user{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 35px;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  right: 110%;
  font-size: 13px;
  padding: 10px;
  background: #f1bd6c;
  border-radius: 4px;
  color: #1e1f21;
}
.header_home_Team_user .header_home_Team_user_button {
  color: #fff;
}

@media screen and (max-width:1366px){
  .header_home_Team_user {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 30px;
    border-radius: 15px;
    /* background-color: #e5b350; */
    overflow: hidden;
    position: relative;
    /* right: 60%; */
    font-size: 13px;
    padding: 10px;
    background: #f1bd6c;
    border-radius: 4px;
    color: #1e1f21;
}
}

