.mention-container {
    width: 100%;
    height: 40px;;
    overflow-y: auto;
    z-index: 999999 !important;
}


.mention-container .public-DraftStyleDefault-block {
    margin: 0 ;
    position: relative;
}
.public-DraftStyleDefault-block.public-DraftStyleDefault-ltr {
    padding-top: 8px;
}

