
.add-task-container{
    height: calc(var(--session-row-height) * 2);
}

.onerow{
    height: var(--session-row-height);
}



.add-task-row{
    height: var(--session-row-height);
    margin-left: -24px;
    margin-right: -24px;
    position: relative;
    background-color: var(--add-row-color);
  

   .add-task-input{
       height: 100%;
       display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        

    .session-row-title{
        display: flex;
        flex:1;
        height: 100%;
        width: 100%;
        background-color: var(--white-color);
        border: 1px solid var(--btn-primary-color);
        border-left: none;
    

        &:hover .drag-icon {
            display: block;
        }

        &:hover .row-item {
            display: flex;
        }

   }

   .row-title{
    padding-left: 62px;
    display: flex;
    align-items: center;
    gap: 6px;
    width: 100%;

    

    .row-title-ip{
        font-size: 16px;
        color: black;
        border: none;
        outline: none;
        width: 100%;
    }

  

    .row-title-check-icon{
        font-size: 18px;
        color: var(--text-color);
        opacity: 0.8;
        &:hover{
            color: #58a182;
        }
    }

   }

   .row-item{
    display: flex;
    align-items: center;
    gap: 8px;
     min-width: 125px;
     overflow: hidden;

       .row-item-icon{
           height: 28px;
           width: 28px;
           padding: 6px;
           border-radius: 4px;
        cursor: pointer;
           &:hover{
            color: black;
               background-color: #e6e3e3;
           }
       }


       .row-item-detail{
           display: flex;
           align-items: center;
            margin-right: 5px;
            padding:0 6px;
            border-radius: 4px;
            height: 20px;
            cursor: pointer;
          

            .row-item-detail-icon{
                font-size: 18px;
            }

            span{
                font-size: 14px;
                font-weight: 500;
            }
            
            &:hover{
                color: black;
                background-color: #e6e3e3;
            }
      
       }
   }
   .rowitem-hasDt{
    min-width: 60px;
        .row-item-detail{
        position: absolute;
        right: -100%;
     }
}

   }

   
}

.add-task-col{
    width: 200px;
    height: 100%;
    border-bottom: 1px solid var(--border-color);
    border-right: 1px solid var(--border-color);
    display: flex;
    align-items: center;
    padding: 8px;

    .row-col-icon{
        display: none;

        &:hover{
            color: black;
        }
    }


    &:hover{
        border: 1px solid var(--border-hover-color);
    }
}

.date{
    &:hover .row-col-icon{
        display: block;
    }
}

.add-task-col:nth-last-child(1){
    margin-right: 74px;

}


