.dt-header-container {
    background-color: var(--deep-colorasana-color);
    display: flex;
    height: 57px;
    border-bottom: 1px solid var(--border-color);
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2 !important;

    .dt-header-main{
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        overflow: hidden;


        .main-complete-btn{
            color: black;
            border-radius: 6px;
            border: 1px solid var(--border-color);
            position: absolute;
            width: fit-content;
            top: 50%;
            transform: translateY(-50%);
            transition: all 0.3s ease-out;



            &:hover{
                cursor: pointer;
                border: 1px solid var(--detail-success-color);
                color: var(--detail-success-color);
                background-color: var(--detail-success-bgcolor);

            }

            .mark-complete-datails {
                display: flex;
                align-items: center;
                gap: 6px;
                padding: 2px 5px;
                border-radius: 6px;

            }

            .completed {
                border: 1px solid var(--detail-success-color);
                color: var(--detail-success-color);
                background-color: var(--detail-success-bgcolor);

            }



           
        }

        .slide  {
            top: -100%;
            transition: all 0.3s ease-out;
        }

        

        .main-title{
            position: absolute;
            display: flex;
            align-items: center;
            gap: 6px;
            width: 90%;
            bottom: -100%;
            transition: all 0.3s ease-out;
            height: 100%;
            
            h3 {max-width: 90%;}

            .row-title-check-icon{
                font-size: 16px;
                cursor: pointer;

                &:hover{
                    color: var(--detail-success-color);
                   
                }
            }

            h3{
                margin: 0;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                font-size: 17px;
                font-weight: 500;
                line-height: 56px;
                cursor: pointer;
            }
        }

        .slide2 {
            bottom: 0;
                transition: all 0.3s ease-out;
        }
    }



    .dt-header-item {
        display: flex;
        gap: 10px;
        align-items: center;

        .item-icon{
            height: 30px;
            width: 30px;
            padding: 0 5px;
            color: var(--text-color);
            opacity: 0.8;
            border-radius: 6px;
            cursor: pointer;

            &:hover {
                background-color: var(--icon-hover-background-color);
            }
        }
    }
}

