

.dt-content-container {
    height: calc(100% - 56px);
    position: relative;

}

.Loading-container {
    height: 100%;
   display: flex;
   justify-content: center;
}

.Lock-container {
    height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;

   .lock-content {

       display: flex;
       flex-direction: column;
       align-items: center;
       justify-content: center;
          .lock-icon {
           font-size: 8rem;
          }

          h2 {
            color: var(--text-color);
          }
   }
}
