
.session-row-container{
    height: var(--session-row-height);
    border-bottom: 1px solid var(--border-color);
}



.session-row{
    height: 100%;
   margin-left: -24px;
   margin-right: -24px;
   position: relative;
   display: flex;
   align-items: center;
   justify-content: space-between;
    cursor: pointer;
   &:hover{
       background-color: var(--table-row-hover-color);
   }

   .session-row-title{
        display: flex;
        flex:1;
        height: 100%;
        width: 100%;
        border-right: 1px solid var(--border-color);
        

        &:hover{
            border: 1px solid var(--border-hover-color);
            border-left: none;
        }

        &:hover .drag-icon {
            display: block;
        }

        &:hover .row-item-icon {
            display: block;
        }
        
        &:hover .row-item-detail {
            display: flex;
        }

        &:hover input {
            background-color: var(--white-color);
            border: 1px solid var(--border-hover-color);

        }

   }

   

   .row-title{
    padding-left: 62px;
    display: flex;
    align-items: center;
    gap: 4px;
    width: 100%;

    input{
        font-size: 16px;
        color: black;
        border: none;
        width: 100%;
        padding: 0 4px;
        border-radius: 4px;
        border: 1px solid transparent;
        line-height: 12px;
        background-color: transparent;
        outline: none;
        margin-right: 8px;
    }
   

    .row-title-check-icon{
        font-size: 18px;
        color: var(--text-color);
        opacity: 0.8;
        &:hover{
            color: #58a182;
        }
    }

   }

   
   .row-item{
       display: flex;
       align-items: center;
       gap: 8px;
        min-width: 125px;

       .row-item-icon{
           display: none;
           height: 28px;
           width: 28px;
           padding: 6px;
           border-radius: 4px;
            cursor: pointer;
           &:hover{
            color: black;
               background-color: #e6e3e3;
           }
       }

      
       



       .row-item-detail{
           align-items: center;
            margin-right: 5px;
            padding:0 6px;
            border-radius: 4px;
            height: 20px;
            cursor: pointer;
           display: none;


            .row-item-detail-icon{
                font-size: 18px;
            }

            span{
                font-size: 14px;
                font-weight: 500;
            }
            
            &:hover{
                color: black;

                background-color: #e6e3e3;
            }
      
       }
   }

   .rowitem-hasDt{
       min-width: 60px;

       
}
.focus {
    border: 1px solid var( --btn-primary-color);
    border-left: none;
    background-color: var(--white-color);
    .row-item{
        display: flex;

        .row-item-icon {
            display: block ;
        }

        .row-item-detail{
            display: flex;
        }
    }

    &:hover{
        border: 1px solid var(--btn-primary-color);
        border-left: none;
    }
    &:hover input {
        background-color: var(--white-color);
        border: none;

    }

   }
}

.session-row-col{
    width: 150px;
    // width: 200px;
    height: 100%;
    border: 1px solid transparent;
    border-right: 1px solid var(--border-color);
    display: flex;
    align-items: center;
    padding: 8px;
    position: relative;

    .row-col-icon{
        display: none;

        &:hover{
            color: black;
        }
    }


    &:hover{
        border: 1px solid var(--border-hover-color);
    }
    
    .ant-picker  {
        border: none;
        background-color: transparent;
        width: 100%;
    }
}

.date{
    &:hover .row-col-icon{
        display: block;
    }
}

.session-row-col:nth-last-child(1){
    margin-right: 74px;

}

.selected-row {
    background-color: var(--select-row-color);
    &:hover{
        background-color: var(--select-row-color);
    }

    &:hover .session-row-title {
        border: 1px solid var(--btn-primary-color);


    }
}

.assignee {
    display: flex;
    gap: 10px;
    align-items: center;
    width: 100%;
    img {
        height: 25px;
        width: 25px;
        border-radius: 50%;
    }

    p {
        flex: 1;
        text-overflow: ellipsis;
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
    }

    .remove-assign {
        padding: 4px;
        border-radius: 6px;
        font-size: 20px;
        color: gray;

        &:hover {
            background-color: white;
        }
    }
}