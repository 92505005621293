:root {
  --btn-primary-color: #4574d2;
  --btn-primary-color-hover: #426dc6;
  --border-color: #edeae9;
  --border-hover-color: #afabac;
  --white-color: #fbfbfb;
  --deep-white-color: #ffffff;
  --deep-colorasana-color:#f9f7f8;
  --text-color: #6d6e6f;
  --text-hover-background-color: #f5f1f1;
  --icon-hover-background-color: #eceaea;
  --table-row-hover-color: #ececec;
  --add-row-color: #ecececce;
  --select-row-color: #f1f2fc;
  --footer-bg-color: #f9f8f8;
  --like-btn-color: #406ac4;

  --detail-border-color: rgb(184, 180, 180);
  --detail-success-color: #58a182;
  --detail-success-bgcolor: #e1f4ec;
  --detail-gray-color: #ecebeb;
  --detail-button-hover-color: #dbdbdb;

  --textedit-active-bgcolor: #e4e6f5;

  --editor-border-color: #cfcbcb;

  --tool-tip-bg-color: #363636;

  --session-row-height: 38px;
  --subtask-row-height: 34px;

  --page-header-height: 64px;
  --my-task-header-height: 56px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.hide {
  display: none;
}

.extend {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  transition: ease 0.1s;
}

.drag-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 6px;
  font-size: 16px;

  &:hover {
    cursor: move;
  }
}

.sideBar-container {
  z-index: 100;
}

.rdw-option-wrapper[title="Bold"] {
  display: none;
}

.rdw-option-wrapper[title="Italic"] {
  display: none;
}

.rdw-option-wrapper[title="Underline"] {
  display: none;
}

.rdw-option-wrapper[title="Strikethrough"] {
  display: none;
}

.rdw-option-wrapper[title="Monospace"] {
  display: none;
}

.rdw-option-wrapper[title="Superscript"] {
  display: none;
}

.rdw-option-wrapper[title="Remove"] {
  display: none;
}

.rdw-option-wrapper[title="Embedded"] {
  display: none;
}

.rdw-option-wrapper[title="Image"] {
  display: none;
}

.rdw-option-wrapper[title="Indent"] {
  display: none;
}

.rdw-option-wrapper[title="Outdent"] {
  display: none;
}

.rdw-option-wrapper[title="Subscript"] {
  display: none;
}

.rdw-option-wrapper[title="Unordered"] {
  display: none;
}

.rdw-option-wrapper[title="Ordered"] {
  display: none;
}

.ant-popover-arrow-content::before {
  background-color: #363636;
}
