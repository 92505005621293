.layout {
  background: radial-gradient(50% 50% at 50% 50%, #ffffff 0%, #ffffff 100%);
  /* background-image: radial-gradient(
    circle farthest-corner at 10% 20%,
    rgba(222, 168, 248, 1) 0%,
    rgba(168, 222, 248, 1) 21.8%,
    rgba(189, 250, 205, 1) 35.6%,
    rgba(243, 250, 189, 1) 52.9%,
    rgba(250, 227, 189, 1) 66.8%,
    rgba(248, 172, 172, 1) 90%,
    rgba(254, 211, 252, 1) 99.7%
  ); */
  background-image: radial-gradient(
    circle farthest-corner at 10% 20%,
    rgba(130, 205, 221, 1) 0%,
    rgba(255, 247, 153, 1) 90%
    
  );
  background-size: "cover";
}

.container {
  max-width: 408px;
  margin: auto;
}

.title {
  text-align: center;
  color: #626262;
  font-size: 30px;
  letter-spacing: -0.04em;
}

.imageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}
.ant-form{
  padding: 20px 20px 0 20px;
}
/* .ant-input {
  width: 90%;
  margin-left: 5%;
}
.ant-btn.ant-btn-block{
  width: 90%;
  margin-left: 5%;
} */
.ant-form-vertical .ant-form-item-label > label{
  margin-left: 20px
}
.ant-checkbox-wrapper{
  margin-left: 20px;
}
.ant-card-bordered {
  border: 1px solid #686868;
}
.ant-btn-primary {
  color: #fff;
  border-color: #1890ff;
  background-image: linear-gradient( 109.5deg, rgba(76, 221, 242, 1) 11.2%, rgba(92, 121, 255, 1) 91.1% );
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}
.ant-card.ant-card-bordered {
  background-color: #fff;
  margin-top: 10px;
  height: 390px;
}


#container_family {
  background: linear-gradient(2deg, #e7193f, #ffeb00, #ffffff, #008cff);
  background-size: 800% 800%;
  animation: gradient 10s ease infinite;
  position: absolute;
  left: 50%;
  top: -9%;
  transform: translate(-50%, -50%);
  padding: 10px;
  font-size: 36px;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  letter-spacing: 2px;
}

@keyframes gradient {
  0% {
      background-position:0% 70%
  }
  50% {
    background-position:100% 30%
  }
  100% {
    background-position:0% 70%
  }
}