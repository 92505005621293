

.ant-page-header{
  background-color:inherit;
  width: 40%;
}
.ant-card{
  background-color: inherit;
  margin-top: 10px;
}
.ant-card-actions{
  background-color: inherit;
}
.ant-card-body{
  padding: 0;
}
.ant-space.ant-space-horizontal.ant-space-align-center {
  float: inherit !important;
}
.ant-page-header-heading-title {
  font-weight: inherit;
  font-size: 27px;
}

.ant-row.ant-form-item {
  display: flex;
  flex-direction: column;
}
form#control-hooks {
  float: left;
}
.ant-row.ant-form-item {
  margin: 0;
  width: 520px;
}
.ant-col.ant-col-8.ant-form-item-label {
  display: flex;
}
.ant-col.ant-col-16.ant-col-offset-8.ant-form-item-control {
  width: 346px;
  margin: 0 124px;
}
.create-form {
  width: 390px;
  height: 340px;
  background-color: inherit;
  margin: auto;
  border: 1px solid #ddd;
  background-color: azure;
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 30%) 0px 19px 38px, rgb(0 0 0 / 22%) 0px 15px 12px;
}
.create-form-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
}
.create-form h3 {
  font-size: 27px;
  text-align: center;
  padding: 12px 12px 0px 12px;
}
.ant-form-item-control-input-content {
  flex: auto;
  max-width: 353px;
}
.open-new-tab{
  color: rgb(56, 54, 54);
}