.wrapper-project {
  display: flex;
  justify-content: center;
  /* padding: 10px 0; */
}
.container-project {
  height: calc(100vh - 380px);
  overflow-y: auto;
  width: 827px;
  margin: 0 auto;
}
.project-content {
  width: 800px;
}
.line {
  width: 725px;
  height: 1px;
  background-color: #ddd;
  margin-left: 37px;
  margin-top: 4px;
}
.wrapper-new-project {
  display: flex;
  align-items: center;
}
.new-project {
  width: 725px;
  display: flex;
  align-items: center;
  padding: 10px 0;
  /* border-top: 1px solid #ddd; */
  margin-left: 37px;
}
.project-content:hover {
  background-color: #ddd;
  border-radius: 16px;
  cursor: pointer;
}
.wrap-icon {
  background-color: #b36bd4;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-size: 28px;
  color: #fff;
  margin-right: 15px;
}
p.name,
p.status {
  margin-bottom: 0;
}
p.name {
  margin-bottom: 0;
  font-size: 17px;
  font-weight: 600;
}
p.status {
  font-size: 13px;
  display: flex;
  margin-top: 4px;
}
svg.private-icon {
  margin-right: 2px;
}
span.star {
  margin-left: 6px;
  position: absolute;
  display: none;
}
.icon-more {
  margin-right: 37px;
  width: 174px;
  position: relative;
}
.dropdown {
  position: absolute;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 4px 6px;
}
.dropdown p {
  margin: 0;
}

.ant-space-item {
  padding: 7px 9px 3px 9px;
  border-radius: 10px;
  margin-top: 10px;
  color: #444141;
}
.ant-space-item:hover {
  background-color: transparent;
}
a:hover {
  color: initial;
}
svg.icon-edit {
  display: flex;
  padding: 4px;
  width: 26px;
  height: 22px;
  /* background: #ccc; */
  justify-content: center;
  align-items: center;
}
svg.icon-edit:hover {
  background-color: #ccc;
  border-radius: 5px;
}
@media screen and (min-width: 1845px) {
  .container-project {
    height: calc(100vh - 380px);
    overflow-y: auto;
    width: 827px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 1366px) {
  .container-project {
    height: calc(100vh - 380px);
    overflow-y: auto;
    width: 827px;
    margin: 0 auto;
  }
}
