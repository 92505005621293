.subtask-queue-warpper {
    display: flex;
    flex-direction: column;
    padding: 10px 24px;
    gap: 4px;
    text-decoration: underline;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;

    .queue-item {
        width: fit-content;
        &:hover {
            color: black;
        }
    }

}