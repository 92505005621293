
.box {
    width: 20%;
  }
  .box a {
    color: #303030;
  }
  .button:hover {
    color: rgb(85, 82, 82);
  }
  
  .overlay {
    position: absolute;
    position: fixed;
    z-index: 9999;
    top: -300px;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);  
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease;
  }
  
  .targetmember {
    
    visibility: visible;
    opacity: 1;
    top:0px;
  }
  .display_none{
    display: none;
  }
  
  .popup {
    width: 69%;
    height: auto;
    margin: 200px auto;
    padding: 20px;
    background: #fff;
    border-radius: 5px;
    position: relative;
    transition: all 1s ease-in-out;
  }
  
  .popup h2 {
    margin-top: 0;
    color: #333;
    font-family: Tahoma, Arial, sans-serif;
  }
  
  .popup .close {
    position: absolute;
    top: 20px;
    right: 30px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: #333;
    
  }
  
  .popup .close:hover {
    color: orange;
    transform: rotate(90deg);
  }
  
  .popup .content {
    max-height: 30%;
    overflow: auto;
  }
  
  .popup_modal_member{
    width: 70%;
    height: 30px;
  }
  
  .popup_modal_member_shared{
    width: 70%;
    height: 50px;
  }
  .ProjectmemberDialog{
    align-items: center;
    justify-content: space-between;
    display: flex;
    height: 50px;
    padding: 4px;
    
  }
  .project_member-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
}
  .ProjectmemberDialog_avatar{
    align-items: center;
      background: #a0a2a2;
      border-radius: 16px;
      display: flex;
      fill: #fff;
      flex-shrink: 0;
      height: 32px;
      justify-content: center;
      margin-right: 8px;
      width: 32px;
  }
  svg:not(:root){
    overflow: hidden;
  }
  .UsersIcon{
    flex: 0 0 auto;
    height: 16px;
    width: 16px;
  }
  .ProjectmemberDialog_description{
      width: auto;
      margin-right: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
  }
  .Typography{
    font-size: 12px;
    line-height: 18px;
  }
  .ProjectmemberDialog_dropdow{
    display: flex;
    padding: 0;
  }
  .overlay_mention_input{
      width: 420px;
      height: 40px;
      border: 1px solid;
  }
  .overlay_mention{
    position: fixed;
    z-index: 99999999;
    width: 300px;
    height: 40px;
    border: 1px solid;
    border-radius: 10px;

  }

  .overlay_input_margintop{
    margin-top: 30px;
    overflow-y: auto;
    height: 380px;
  } 


  @media screen and (min-width:1300px){
    .popup {
      width: 65%;
      height: auto;
      margin: 130px auto;
      padding: 20px;
      background: #fff;
      border-radius: 5px;
      position: relative;
      transition: all 1s ease-in-out;
    }
    .ProjectmemberDialog_description{
      width: auto;
      margin-right: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
  }
  }

  
  @media screen and (min-width:1845px){
    .popup {
      width: 45%;
      height: auto;
      margin: 200px auto;
      padding: 20px;
      background: #fff;
      border-radius: 5px;
      position: relative;
      transition: all 1s ease-in-out;
    }
    .ProjectmemberDialog_description{
      width: auto;
      margin-right: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
  }
  }
  #gridContainer {
    height: 440px;
  }
  
  .dx-gridbase-container{
    
    height: 440px;
  }
  .action_manage_member{
    display: flex;
    align-self: center;
    align-items: center;
  }
  .action_item_title{
    margin-right: 100px;
  }
  .action_item_button{
    display: flex;
    align-self: center;
    align-items: center;
    margin-left: 40px;
    font-weight: 600;
  }
  .action_detail_button{
    margin: 0 10px;
  }
  .action_detail_button{
    margin-left: 40px;
  }
  input[type='checkbox']{
    accent-color: #3cbab2;
    background-color: #f5f5f5;
  }
