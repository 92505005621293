#cauhinhchitietform{
    height: calc(100vh - 160px);
}
.hidden{
    display: none;
}
.dehidden{
display: block;
}
#closePopupInsert:hover{
background-color: #cfcbcb;
}
#closePopupUpdate:hover{
background-color: #cfcbcb;
}