.container_form_de_xuat {
    display: flex;
    background-color: white;
    height: 100vh;
    margin: -24px;
    padding: 0 24px;
}
.container_form_de_xuat_fixed {
    right: 0;
    padding: 0 24px;
    position: fixed;
    left: 0;
    bottom: 0;
    overflow-y: auto;
    overflow-x: hidden;
}
.container_form_de_xuat_scroll {
    right: 0;
    padding: 0 24px;
    position: fixed;
    bottom: 0;
    overflow: scroll;
    left: 26rem;
    height: 100%;
}
.d-flex {
    display: flex;
    flex-direction: row;
    background: #f6f6f6;
    border-radius: 0 0 5px 5px;
    padding: 25px;
}
.form_de_xuat {
    width: 80%;
    // margin: auto;
    flex: 2;
    background-color: #f3f3f3;
    border-radius: 10px;
    padding-left: 4.5rem;
    margin-top: 4.5rem;
    padding-bottom: 1rem;
    label,
    table {
        display: block;
        margin: 15px;
        padding-top: 10px;
    }
    label > span {
        float: left;
        width: 25%;
        margin-top: 12px;
        padding-right: 10px;
    }
}
.Yorder {
    flex: 2;
}

.required {
    color: red;
}

.input_de_xuat {
    width: 100%;
    height: 30px;
    padding: 5px 10px;
    margin-bottom: 10px;
    border: 1px solid #dadada;
    color: #888;
    border-radius: 5px;
    outline: none;
}
.Yorder {
    margin-top: 15px;
    height: 600px;
    padding: 20px;
    border: 1px solid #dadada;
}
.Yorder > div {
    padding: 15px 0;
}

.button_de_xuat {
    width: 20%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background: #52ad9c;
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    margin-left: 1rem;
    &:hover {
        cursor: pointer;
        background: #428a7d;
    }
}
.texarea {
    width: 100%;
    height: 122px;
    resize: none;
    border: 1px solid #dadada;
    border-radius: 5px;
    outline: none;
}
.custom-file-upload {
    display: inline-block;
    overflow: hidden;
    padding: 6px 12px;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 5px;
    &:hover {
        cursor: pointer;
        background: #f1ede8;
    }
}

.custom-file-upload input {
    position: absolute;
    font-size: 100px;
    right: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
}

.custom-file-upload span {
    z-index: -1;
}
.fname {
    padding-bottom: 10px;
}
.fontChu {
    color: #000000;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
.file-here {
    text-align: center;
    padding-top: 11px;
    font-size: 13px;
}
.file-store {
    width: 100%;
    height: 150px;
    background: #ffffff;
    border-radius: 5px;
    overflow: scroll;
}
.file {
    height: 35px;
}

.error {
    color: red;
}
.mention {
    width: 100%;
    height: 120px;
    background-color: #ffffff;
    border: 1px solid #dadada;
    border-radius: 5px;
    overflow: scroll;
    .DraftEditor-root {
        .DraftEditor-editorContainer {
            .public-DraftEditor-content {
                .public-DraftStyleDefault-block {
                    margin: 0;
                    padding: 0;
                }
            }
        }
    }
}
.custom-editor-style {
    width: 100%;
    height: 122px;
    resize: none;
    border: 1px solid #dadada;
    border-radius: 5px;
    outline: none;
    // overflow: auto;
    background-color: #ffffff;
    .DraftEditor-root {
        .DraftEditor-editorContainer {
            .public-DraftEditor-content {
                .public-DraftStyleDefault-block {
                    margin: 0;
                    padding: 0;
                }
            }
        }
    }
}
.dateBox {
    width: 100%;
}
.font-weight {
    font-weight: 600;
}
.fix {
    text-align: center;
    padding-top: 10px;
}
.overflow-auto {
    overflow: auto;
}

.container_form_de_xuat_success {
    background-color: rgb(249 249 249);
    height: 100vh;
    margin: -24px;
    padding: 0 24px;
}
.container_success {
    width: 100%;
    height: 100%;
    text-align: center;
    position: fixed;
    top: 300px;
    bottom: 0;
    right: 0px;
    left: 100px;
    font-size: 20px;
    font-family: revert;
}
.success {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5px;
    padding-right: 10px;
    font-size: 20px;
}
// .button_success {
//     padding: 5px;
//     border: 1px solid #bfbfbf;
//     border-radius: 5px;
//     cursor: pointer;
//     font-size: 15px;
//     &:hover {
//         cursor: pointer;
//         background: #77e779;
//     }
// }

.button-container {
    position: relative;
    display: inline-block;

    .button {
        background: none;
        border: none;
        padding: 0;
        margin: 0;
        .button_success::before {
            transform: scaleX(0);
        }

        .button_success:hover::before {
            transform: scaleX(1);
        }

        .button_success::before {
            content: ' ';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-color: rgb(131 227 160 / 40%);
            border-radius: 6px;
            z-index: -1;
            transition: transform 1s ease;
            cursor: pointer;
        }

        .button_success {
            position: relative;
            font-size: 20px;
            padding: 5px 20px 5px 20px;
            border: 1px solid #d1d1d1;
            border-radius: 10px;
            color: dimgrey;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Helvetica, Arial,
                sans-serif;
            cursor: pointer;
        }
    }
}
