.info-items {
    display: flex;
    // gap: 75px;

    .info-label {
        font-size: 14px;
        font-weight: 500;
        color: var(--text-color);
        min-width: 110px;
    }
}

.listproject {
    display: flex;
    flex-direction: column;
    // gap: 10px;
    // margin-bottom: 10px;
}


.projects-details {
    display: flex;
    flex-direction: column;
    // gap: 10px;
    padding: 0 12px;

    .project-btn {
        padding: 6px 12px;
        cursor: pointer;
        max-width: fit-content;
        min-width: 145px;
        &:hover {
            border-radius: 6px;
            background-color: var(--icon-hover-background-color);
            color: black;
        }
    }
}