.like-list-wrapper {
    // display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    max-height: 300px;
    overflow-y: auto;
    // min-height: 40px;
    position: relative;

    .list-container {
        display: flex;
        gap: 10px;
        color: white;

        .name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 150px;
            font-size: 13px;
        }

        .time {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 80px;
            font-size: 12px;
        }
    }
}