.header_project{
  width: 100%;

}
h1{
  font-size: 15px;
}
.header_container_project{
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  /* border-bottom: 1px solid #edeae9; */
}
.Topbar_header_list{
  width: 300px;
  display: flex;
  list-style: none;
  padding: 0;
  justify-content: space-between;
}
.topbar_header_li{
  /* padding: 3px; */
  display: inline-block;
  cursor: pointer;
  font-size: 12px;
}
.topbar_header_li::after{
  content: "";
  display: block;
  width: 0;
  height: 1px;
  margin-top: 5px;
  background-color: #000;
  transition: width .7s;
}
.topbar_header_li:hover::after{
  width: 100%;
}
.topbar_header_message{
  max-width: 100%;
  margin-top: 10px;
}
.topbar_header_message_button{
  width: 115px;
  border: 1px solid rgb(173 173 173);
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  font-size: 12px;
}
.topbar_header_message :hover h3{
  background: #f9f8f8;
  border-color: #afabac;
  /* color: #1e1f21;
  fill: #1e1f21; */
}
.topbar_header_icon{
  padding-right: 7px;
}
.large_font {
  font-size: 19px;
  cursor: pointer;
}
.text_center {
  font-size: 20px;
  cursor: pointer;
  fill: #d1d100;
  margin-left: 4px;
}
.text_centernone {
  font-size: 19px;
  cursor: pointer;
  margin-left: 6px;

}

.header-project-title p{
  font-weight: 400;
  max-width: 550px;
  margin-top: 2px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: sans-serif;
}
