.Log-container {
    padding: 8px 24px;
    background-color: var(--footer-bg-color);

    .complete-log {
        display: flex;
        align-items: center;
        gap: 10px;

        .log-content {
            display: flex;
            align-items: center;
            gap: 10px;
        }
        span {
            color: var( --detail-success-color);
            font-size: 14px;
            font-weight: 500
        };

        .created-time {
            color: var(--text-color);
            font-size: 12px;
            font-weight: normal;
        }
        .complete-icon {
            color: var( --detail-success-color);

            height: 30px;
            width: 30px;
        }

        .uncomplete-icon {
            color: var(--text-color);
            height: 30px;
            width: 30px;
        }

        .uncomplete-para {
            color: var(--text-color);

        }
        
        .common-log {
            color: var(--text-color);
            font-size: 12px;
            font-weight: normal;
            margin-left: 40px; 
            display: flex;

            span {
                color: var(--text-color);
                font-size: 12px;
                font-weight: normal;

            }

            .log-username {
                font-weight: 500;
            }

            .common-created-time {
                margin-left: 10px;
                
            }
        }
    }
}