.mt-details-container{
    position: fixed;
    background-color: var(--deep-colorasana-color);
    border-left: 1px solid var(--border-color);
    top: var(--page-header-height);
    bottom: 0;
    right: -55%;
    width: 55%;
    z-index: 98;
    transition: ease 0.3s;
    overflow-y: auto;
    overflow-x: hidden;

    .ant-picker  {
        padding: 4px 0;
    }

    .ant-picker-focused  {
        box-shadow: none;
      }
}

.show{
    right: 0;
    transition: ease 0.3s;
    
}

