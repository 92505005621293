
.header_container_top {
    width: 100%;
    height: 64px;
    display: flex;
    justify-content:space-around;
    align-items: center
 }

@media screen and (max-width:1366px){
    .header_container_top {
     width: 100%;
     height: 64px;
     display: flex;
     justify-content: space-evenly;
     align-items: center
  }
  }