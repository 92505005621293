html *::-webkit-scrollbar {
  border-radius: 0;
  width: 8px;
  margin-right: 2px;
}
html *::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color:rgba(157, 177, 235, 0.44);
}
html *::-webkit-scrollbar-track {
  border-radius: 0;
  background-color: rgba(151, 190, 241, 0);
}

.ant-menu-inline {
  .ant-menu-submenu {
    .ant-menu-submenu-title {
      height: 30px;
      line-height: 22px;
    }
  }
}
.favorites {
  .ant-menu-sub {
    .ant-menu-item {
      height: 24px;
      .ant-menu-item {
        padding-left: 5px !important;
        height: 26px;
        font-size: 14px !important;
      }
      .ant-menu-item-icon {
        font-size: 12px !important;
      }
    }
  }
}

.favorites {
  .ant-menu-sub {
    max-height: 180px;
    overflow-y: initial;
    overflow-x: hidden;
  }
}

.team-menu > .ant-menu-sub:nth-child(2) {
  max-height: calc(100vh - 360px);
  overflow-y: initial;
  overflow-x: hidden;
}

.team-menu > .ant-menu-sub:nth-child(2)::-webkit-scrollbar-track,
.favorites > .ant-menu-sub::-webkit-scrollbar-track {
  border-radius: 0;
  background-color: rgba(0, 0, 0, 0);
}

.slide-menu *::-webkit-scrollbar-thumb,
.slide-menu *::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(228, 224, 224, 0.637);
}

.slide-menu *::-webkit-scrollbar {
  border-radius: 0;
  width: 4px;
  margin-right: 2px;
}

.menuItemSidebar{
  border-bottom: 1px solid #818181;
  .ant-menu-inline:nth-child(2) {
    max-height: calc(100vh - 360px);
    overflow-x: hidden;
    font-size: 12px;
    font-family: sans-serif;
  }
  .ant-menu-inline:nth-child(2)::-webkit-scrollbar-track,
  .ant-menu-inline  *::-webkit-scrollbar-thumb,
  .ant-menu-inline  *::-webkit-scrollbar-thumb{
    border-radius: 4px;
  }
  .ant-menu-inline  *::-webkit-scrollbar{
    border-radius: 0;
    width: 4px;
    margin-right: 2px;
  }
}
.menuItemSidebar{
  .ant-menu-inline {
    .ant-menu-sub{
      max-height: calc(100vh);
      overflow: hidden;
      font-size: 12px;
    }
  }

}

.menuItemFavorites  {
  border-bottom: 1px solid #818181;
  border-top: 1px solid #818181;
  .ant-menu-inline{
    max-height: calc(100vh);
    overflow-x: hidden;
    font-size: 12px;
    font-family: sans-serif;
  }
}
.menuItemSidebar{
  .ant-menu-inline{
    .ant-menu-submenu-open{
      .ant-menu-inline{
        // .ant-menu-item:hover{
        //   background-color: #525252d1;
        //   border-radius: 10px;
        // }
      }
    }
  }
}
.menuItemSidebar{
  .ant-menu-sub{
    .ant-menu-submenu-inline{
      margin-left: -15px;
      .ant-menu-sub{
        margin-left: -25px;
        .ant-menu-item{
          padding-left: 44px;
        }
      }
    }
  }
}
.menuItemSidebar{
  .ant-menu-sub{
    .ant-menu-submenu-inline{
      .ant-menu-submenu-title{
        height: 24px;
      }
      .ant-menu-sub{
        .ant-menu-item{
          height: 20px;
        }
      }
    }
  }
}
.menuItemFavorites{
 .ant-menu-sub{
   .ant-menu-item{
    height: 24px;
    margin-left: -14px;
   }
 }
}

.ant-menu-vertical > .ant-menu-item, .ant-menu-vertical-left > .ant-menu-item, .ant-menu-vertical-right > .ant-menu-item, .ant-menu-inline > .ant-menu-item, .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title{
  height:25px;
}

.menuItemSidebar{
  .ant-menu-inline{
    .ant-menu-submenu-open{
      .ant-menu-sub{
        .displaynone{
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width:1900px){
  .menuItemFavorites  {
    border-bottom: 1px solid #818181;
    border-top: 1px solid #818181;
    .ant-menu-inline{
      max-height: calc(100vh - 600px);
      overflow-x: hidden;
      font-size: 12px;
      font-family: sans-serif;
    }
  }

  .menuItemSidebar{
    border-bottom: 1px solid #818181;
    .ant-menu-inline:nth-child(2) {
      max-height: calc(100vh - 288px);
      overflow-x: hidden;
      font-size: 12px;
      font-family: sans-serif;
    }
  }
  .menuItemSidebar{
    .ant-menu-inline {
      .ant-menu-sub{
        max-height: 100%;
        overflow: hidden;
        font-size: 12px;
      }
    }
  
  }
}

@media screen and (max-width:1600px){
  .menuItemFavorites  {
    border-bottom: 1px solid #818181;
    border-top: 1px solid #818181;
    .ant-menu-inline{
      max-height: calc(100vh - 600px);
      overflow-x: hidden;
      font-size: 12px;
      font-family: sans-serif;
    }
  }

  .menuItemSidebar{
    border-bottom: 1px solid #818181;
    .ant-menu-inline:nth-child(2) {
      max-height: calc(100vh - 288px);
      overflow-x: hidden;
      font-size: 12px;
      font-family: sans-serif;
    }
  }
  .menuItemSidebar{
    .ant-menu-inline {
      .ant-menu-sub{
        max-height: 100%;
        overflow: hidden;
        font-size: 12px;
      }
    }
  
  }
}


@media screen and (max-width:1440px){
  .menuItemFavorites  {
    border-bottom: 1px solid #818181;
    border-top: 1px solid #818181;
    .ant-menu-inline{
      max-height: calc(100vh - 600px);
      overflow-x: hidden;
      font-size: 12px;
      font-family: sans-serif;
    }
  }

  .menuItemSidebar{
    border-bottom: 1px solid #818181;
    .ant-menu-inline:nth-child(2) {
      max-height: calc(100vh - 288px);
      overflow-x: hidden;
      font-size: 12px;
      font-family: sans-serif;
    }
  }
  .menuItemSidebar{
    .ant-menu-inline {
      .ant-menu-sub{
        max-height: 100%;
        overflow: hidden;
        font-size: 12px;
      }
    }
  
  }
}

@media screen and (max-width:1366px){
  .menuItemSidebar{
    border-bottom: 1px solid #818181;
    .ant-menu-inline:nth-child(2) {
      max-height: calc(100vh - 287px);
      overflow-x: hidden;
      font-size: 12px;
      font-family: sans-serif;
    }
    .ant-menu-inline:nth-child(2)::-webkit-scrollbar-track,
    .ant-menu-inline  *::-webkit-scrollbar-thumb,
    .ant-menu-inline  *::-webkit-scrollbar-thumb{
      border-radius: 4px;
    }
    .ant-menu-inline  *::-webkit-scrollbar{
      border-radius: 0;
      width: 4px;
      margin-right: 2px;
    }
  }
  .menuItemSidebar{
    .ant-menu-inline {
      .ant-menu-sub{
        max-height: 100%;
        overflow: hidden;
        font-size: 12px;
      }
    }
  
  }
  .menuItemFavorites  {
    border-bottom: 1px solid #818181;
    border-top: 1px solid #818181;
    .ant-menu-inline{
      max-height: calc(100vh - 535px);
      overflow-x: hidden;
      font-size: 12px;
      font-family: sans-serif;
    }
  }
}

.inbox{
  display: flex;
  align-items: center;
  justify-content: center;
}
