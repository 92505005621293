.avatar-img{
    display: flex;
    align-items: center;
    height: 30px;
    background-color: #ffffff;
    border-radius: 50%;
    min-width: 30px;
    justify-content: center;
    font-size: 10px;
    position: relative;
    color: #606060;
}