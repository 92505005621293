@import "~antd/lib/style/themes/default.less";
@import "~antd/dist/antd.less";

// There are some major variables below,
// all less variables could be found in
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

/* montserrat-100 - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif;';
  font-style: normal;
  font-weight: 100;
  src: url('../fonts/montserrat-v25-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-100.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/montserrat-v25-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/montserrat-v25-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-100.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/montserrat-v25-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-100.woff') format('woff'), /* Modern Browsers */
       url('../fonts/montserrat-v25-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-100.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/montserrat-v25-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-100.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-200 - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif;';
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/montserrat-v25-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-200.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/montserrat-v25-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/montserrat-v25-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-200.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/montserrat-v25-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-200.woff') format('woff'), /* Modern Browsers */
       url('../fonts/montserrat-v25-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-200.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/montserrat-v25-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-200.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-300 - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif;';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/montserrat-v25-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/montserrat-v25-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/montserrat-v25-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/montserrat-v25-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/montserrat-v25-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/montserrat-v25-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-300.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-regular - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif;';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/montserrat-v25-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/montserrat-v25-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/montserrat-v25-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/montserrat-v25-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/montserrat-v25-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/montserrat-v25-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-500 - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif;';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/montserrat-v25-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/montserrat-v25-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/montserrat-v25-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/montserrat-v25-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-500.woff') format('woff'), /* Modern Browsers */
       url('../fonts/montserrat-v25-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/montserrat-v25-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-500.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-600 - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif;';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/montserrat-v25-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/montserrat-v25-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/montserrat-v25-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/montserrat-v25-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-600.woff') format('woff'), /* Modern Browsers */
       url('../fonts/montserrat-v25-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/montserrat-v25-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-600.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-700 - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif;';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/montserrat-v25-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/montserrat-v25-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/montserrat-v25-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/montserrat-v25-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/montserrat-v25-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/montserrat-v25-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-800 - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif;';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/montserrat-v25-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-800.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/montserrat-v25-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/montserrat-v25-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-800.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/montserrat-v25-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-800.woff') format('woff'), /* Modern Browsers */
       url('../fonts/montserrat-v25-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-800.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/montserrat-v25-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-800.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-900 - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif;';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/montserrat-v25-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-900.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/montserrat-v25-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/montserrat-v25-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-900.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/montserrat-v25-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-900.woff') format('woff'), /* Modern Browsers */
       url('../fonts/montserrat-v25-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-900.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/montserrat-v25-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-900.svg#Montserrat') format('svg'); /* Legacy iOS */
}
// font
@font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
  Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

// base variables
@border-radius-base: 6px;

// colors
// @primary-color: #67be23;
@error-color: #fa541c;
@text-color: #626262;

// sider
@layout-sider-background: #2a132e;
@menu-bg: transparent;
@menu-item-color: #fff;
@menu-highlight-color: #fff;
@menu-inline-submenu-bg: transparent;
@menu-item-active-bg: transparent;
@menu-item-active-border-width: 0px;
@layout-trigger-background: rgba(0, 0, 0, 0.5);

//form
@label-color: @text-color;

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 0px;
}

.ant-form-item-label > label {
  font-weight: bold;
}

.ant-page-header {
  border-radius: @border-radius-base;
}
.ant-menu-submenu-popup > .ant-menu {
  background-color: #2a132e;
}
.ant-menu-tree-arrow {
  position: absolute;
  top: 50%;
  right: 16px;
  width: 10px;
  color: #fff;
  transform: translateY(-50%);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-tree-arrow::before,
.ant-menu-tree-arrow::after {
  position: absolute;
  width: 6px;
  height: 1.5px;
  background-color: currentcolor;
  border-radius: 2px;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: "";
}
.ant-menu-tree-arrow::before {
  transform: rotate(45deg) translateY(-2.5px);
}
.ant-menu-tree-arrow::after {
  transform: rotate(-45deg) translateY(2.5px);
}

span.anticon.anticon-unordered-list.ant-menu-item-icon {
  display: none;
}
.ant-menu-item .ant-menu-item-icon + span, .ant-menu-submenu-title .ant-menu-item-icon + span, .ant-menu-item .anticon + span, .ant-menu-submenu-title .anticon + span{
  margin-left: 0;
}
.ant-dropdown-menu {
  position: relative;
  margin: 5px
}
.ant-layout.ant-layout-has-sider > .ant-layout, .ant-layout.ant-layout-has-sider > .ant-layout-content {
  width: 0;
  background-color: #fff;
}
