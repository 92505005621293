.project-item-container {
    display: flex;
    gap: 6px;
    align-items: center;
    color: black;
    font-size: 13px;
    margin-bottom: 10px;

    &:hover .remove-btn{
        display: flex;
    }

    .project-name {
        display: flex;
        gap: 4px;
        align-items: center;
        cursor: pointer;
        p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 150px;
            margin: 0;
        }

        .project-tag {
            background-color: aqua;
            height: 6px;
            width: 6px;
            border-radius: 2px;
        }

        padding: 0px 12px;
        border-radius: 10px;

        &:hover {
            background-color: var(--icon-hover-background-color);
        }
    }

    .section-name {
        display: flex;
        gap: 3px;
        align-items: center;
        color: var(--text-color);
        padding: 0px 8px;
        border-radius: 10px;
        cursor: pointer;

        p {
            margin: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100px;
        }


        &:hover {
            background-color: var(--icon-hover-background-color);
        }

    }
    .remove-btn {
        display: flex;
        align-items: center;
        color: var(--text-color);
        cursor: pointer;
        border-radius: 6px;
        padding: 0 2px;
        display: none;

        &:hover {
            background-color: var(--icon-hover-background-color);
        }
    }
}