

.datepicker {
   
}

.ant-picker  {
    border: none;
    background-color: transparent;
    width: 100%;
}

.ant-picker-input {

    input {
        &::placeholder {
            color: var(--text-color);
            font-weight: 500;
            font-size: 14px;
        }
      
    }
}

