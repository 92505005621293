.ant-layout-sider-zero-width-trigger{
    position: absolute;
    top: 10px;
    right: -25px;
    z-index: 1;
    width: 25px;
    height: 42px;
    color: #fff;
    font-size: 18px;
    line-height: 42px;
    text-align: center;
    background: #1e1f21;
    border-radius: 0 6px 6px 0;
    cursor: pointer;
    transition: background 0.3s ease
}