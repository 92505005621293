.mytask-header-container{
    display: flex;
    height: var(--session-row-height);
    background-color: var(--white-color);

    .mytask-header-wrap{
        display: flex;
        height: var(--session-row-height);
        align-items: center;
        border-bottom: 1px solid var(--border-color);
        border-top: 1px solid var(--border-color);
        color: var(--text-color);
        font-weight: 500;
        background-color: var(--white-color);
        width: 100%;
        margin:0 24px;
        padding-right: 50px ;

    .header-col {
        font-size: 12px;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 8px;
        border-right: 1px solid var(--border-color);
        // padding: 0 24px;

        .col-item-icon{
            font-size: 14px;
            display: none;
            width: 26px;
            height: 26px;
            padding: 5px;
            border-radius: 4px;

            &:hover{
            background-color: #e6e3e3;
            }
        }
    }

    .task-name-col{
        flex: 1;
    }

    .due-date-col{
        width: 150px;
        // width: 200px;
        cursor: pointer;
        display: flex;
        gap: 4px;
        justify-content: space-between;
        align-items: center;


        .col-item{
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 4px;
        }
        &:hover{
            background-color: var(--table-row-hover-color);
        }
        &:hover .col-item-icon{
            display: block;
        }
    }
    .created-on-col{
        width: 150px;
        cursor: pointer;
        display: flex;
        gap: 4px;
        justify-content: space-between;
        align-items: center;


        .col-item{
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 4px;
        }
        &:hover{
            background-color: var(--table-row-hover-color);
        }
        &:hover .col-item-icon{
            display: block;
        }
    }
    .projects-col{
        width: 150px;
        // width: 200px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;

        &:hover{
            background-color: var(--table-row-hover-color);
        }
        &:hover .col-item-icon{
            display: block;
        }
    }


    }



    
}

