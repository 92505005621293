.mention {
    color: #4a85bb;
    text-decoration: none;
}

.mentionSuggestions {
    background: #fff;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    max-height: 150px;
    overflow-y: scroll;
    width: 350px;
    border: 1px solid var(--border-color);
    margin-top: 12px;
    left: inherit;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

    z-index: 999999;
}

.mentionSuggestionsEntryContainer {
    display: table;
    width: 100%;
    background-color: #4a85bb;
    z-index: 99999;
}

.mentionSuggestionsEntryContainerLeft,
.mentionSuggestionsEntryContainerRight {
    display: table-cell;
    vertical-align: middle;
    background-color: #4a85bb;
    z-index: 99999;
}

.mentionSuggestionsEntryContainerRight {
    width: 100%;
    padding-left: 8px;
    z-index: 99999;
}

.mentionSuggestionsEntry {
    padding: 7px 10px 3px 10px;
    transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
    display: flex;
    gap: 10px;
    z-index: 99999;
}

.mentionSuggestionsEntry:active {
    background-color: #cce7ff;
    z-index: 99999;
}

.mentionSuggestionsEntryFocused {
    composes: mentionSuggestionsEntry;
    background-color: #e6f3ff;
    z-index: 99999;
}

.mentionSuggestionsEntryText,
.mentionSuggestionsEntryTitle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 99999;
}

.mentionSuggestionsEntryText {
    z-index: 99999;
}

.mentionSuggestionsEntryTitle {
    font-size: 80%;
    color: #a7a7a7;
    z-index: 99999;
}

.mentionSuggestionsEntryAvatar {
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    z-index: 99999;
}
