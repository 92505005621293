.TopbarSearchTypeaheade {
    height: 100%;
    margin-left: 30px;
    padding-left: 8px;
    position: absolute;
    /* right: 0; */
    right: 55px;
    top: 0;
}

.TopbarSearchTypeaheade-searchInput:not(:focus) {
    /* width: 200px; */
}
.TopbarSearchTypeaheade-searchInput {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    transition: background-color 0.15s ease-out, width 0.15s ease-out, border-color 0.15s ease-out;
}
.SearchTextInput {
    outline: none;
    background-color: #fff;
    border-color: #cfcbcb;
    /* border-radius: 16px; */
    border-style: solid;
    border-width: 1px;
    height: 32px;
    padding: 0 16px 0 32px;
    box-sizing: border-box;
    color: #1e1f21;
    width: 500px;
    border-radius: 15px;
    border: 1px solid #a39b9b;
}
input {
    line-height: normal;
}
svg:not(:root) {
    overflow: hidden;
}
.TopbarSearchTypeahead_icon {
    fill: #6d6e6f;
    left: 16px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: fill 0.15s ease-out;
}
.Icon {
    flex: 0 0 auto;
    height: 16px;
    width: 16px;
}

.SearchTextInput ul {
    position: absolute;
    top: 30px;
    left: 0px;
    width: 100%;
    list-style-type: none;
    border: 1px solid #000;
}
.search__ul {
    list-style: none;
    border: 1px solid;
    margin-top: 1px;
    border-radius: 10px;
    background-color: #fbfbfb;
    max-height: calc(100vh - 500px);
    overflow-x: hidden;
}
.search__li {
    background-color: #f0f2f5;
}
.SearchTextInput ul li {
    padding: 10px;
    border-bottom: 1px solid #000;
    list-style: none;
}
.container_search {
    width: 100%;
    line-height: 20px;
    font-size: 14px;
    /* height: 610px; */
    display: initial;
    overflow: overlay;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.search_itemTopbar {
    width: 100%;
    /* height: 30px; */
    display: flex;
    align-items: center;
    border-bottom: 1px solid #c9c9c9;
    padding: 3px;
    color: #000;
    padding-left: 20px;
}
.search_itemTopbar_ul {
    width: 100%;
    border-bottom: 1px solid #c9c9c9;
    list-style: none;
    padding: 10px;
    color: #626262;
    padding-left: 15px;
}
.search_itemTopbar_ul_ul {
    width: 100%;
    list-style: none;
    padding: 20px;
    margin: 0;
    color: #000;
    height: auto;
    /* overflow-y: auto; */
}
.search_itemTopbar_li {
    padding-left: 20px;
    cursor: pointer;
    align-items: center;
    padding-bottom: 2px;
    padding: 10px;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
}
.search_itemTopbar_li_task {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.search_itemTopbar_li_task_text {
    display: flex;
    width: 100%;
}
.search_itemTopbar_li h1 {
    max-width: 420px;
    text-overflow: ellipsis;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    font-weight: 400;
    color: #000;
    color: blck;
    font-family: inherit;
    padding-left: 10px;
    margin: 0;
}
.avatar_search {
    margin-right: 20px;
}
.search_itemTopbar_li:hover {
    background-color: #ebebeb;
    border-radius: 10px;
}
.search_icon_task {
    color: rgb(88, 161, 130);
}

.show_more_left {
    background-color: #fff;
    margin-top: 1px;
    width: 60px;
    text-align: center;
    border: 1px solid;
    border-radius: 20px;
    font-size: 10px;
    cursor: pointer;
}
.show_more_left:hover {
    background-color: #ebebeb;
}
.YourHighlightClass_search {
    padding: 1px;
    background-color: #ecf16e;
    border: 1px solid #9d9090;
    border-radius: 5px;
}

@media screen and (max-width: 1366px) {
    .SearchTextInput {
        outline: none;
        background-color: #fff;
        border-color: #cfcbcb;
        /* border-radius: 16px; */
        border-style: solid;
        border-width: 1px;
        height: 32px;
        padding: 0 16px 0 32px;
        box-sizing: border-box;
        color: #1e1f21;
        width: 500px;
        border-radius: 15px;
        border: 1px solid #a39b9b;
    }
    .search__ul {
        list-style: none;
        border: 1px solid;
        margin-top: 1px;
        border-radius: 10px;
        background-color: #fbfbfb;
        max-height: calc(100vh - 100px);
        overflow-x: hidden;
    }
    .search_itemTopbar_li h1 {
        max-width: 385px;
        text-overflow: ellipsis;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        font-weight: 400;
        color: #000;
        color: blck;
        font-family: inherit;
        padding-left: 10px;
        margin: 0;
    }
    .search_itemTopbar_li {
        padding-left: 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding-bottom: 2px;
        padding-top: 5px;
    }
}

@media screen and (max-width: 1440px) {
    .SearchTextInput {
        outline: none;
        background-color: #fff;
        border-color: #cfcbcb;
        /* border-radius: 16px; */
        border-style: solid;
        border-width: 1px;
        height: 32px;
        padding: 0 16px 0 32px;
        box-sizing: border-box;
        color: #1e1f21;
        width: 500px;
        border-radius: 15px;
        border: 1px solid #a39b9b;
    }
    .search__ul {
        list-style: none;
        border: 1px solid;
        margin-top: 1px;
        border-radius: 10px;
        background-color: #fbfbfb;
        max-height: calc(100vh - 100px);
        overflow-x: hidden;
    }
    .search_itemTopbar_li h1 {
        max-width: 385px;
        text-overflow: ellipsis;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        font-weight: 400;
        color: #000;
        font-family: inherit;
        padding-left: 10px;
        margin: 0;
    }
    .search_itemTopbar_li {
        padding-left: 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding-bottom: 2px;
        padding-top: 5px;
    }
}
