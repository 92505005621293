.details-footer-container {
  position: fixed;
  bottom: 0;
  right: -55%;
  width: 55%;
  background-color: var(--footer-bg-color);
  transition: ease 0.3s;
  overflow-x: hidden;
  border-top: 1px solid var(--border-color);
  border-left: 1px solid var(--border-color);
  box-shadow: 9px 1px 4px rgb(0 0 0 / 16%);
  padding: 0 24px;

  .footer-content {
    display: flex;
    gap: 8px;
    margin: 8px 0;
    max-width: 100%;

    .footer-ava {
      height: 32px;
      width: 32px;

      img {
        height: 32px;
        width: 32px;
        border-radius: 50%;
        border: 1px solid var(--border-color);
      }
    }

    .footer-text-editor {
      max-width: calc(100% - 40px);
      flex: 1;
    }
  }
}

.collaborators {
  width: 100%;
  margin-top: 10px;
  display: flex;

  .box {
    margin-left: 40px;
  }
}
.overlay_input_margintop {
  display: flex;
  flex-direction: column;
  width: 100%;
  .collaborators_items {
    display: flex;
    justify-content: center;
    align-items: center;

    .collaborators_icons {
      flex-basis: 1;
      // width: 100%;
      margin-right: 10px;
    }

    .collaborators_names {
      width: 100%;
      // display: ;
      flex-basis: 7;
    }
    .collaborators_names_delete {
      min-width: 100px;
      display: flex;
      flex-basis: 3;
      right: 0px;
      width: 100%;
      cursor: pointer;
    }
  }
}
