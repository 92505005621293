.mention-container {
    width: 200px;
    z-index: 999999 !important;
}


.mention-container .public-DraftStyleDefault-block {
    margin: 0 ;
    // position: relative;
}

