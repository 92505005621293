.TaskInfo-container{
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .info-item{
        display: flex;
        align-items: center;
        width: 100%;


        .info-label {
            font-size: 14px;
            font-weight: 500;
            color: var(--text-color);
            min-width: 120px;
        }

        .info-detail {
            display: flex;
            align-items: center;
            gap: 6px;
            font-size: 16px;
            color: var(--text-color);
            // opacity: 0.8;
            font-weight: 400;
            padding: 6px 12px;
            border-radius: 6px;
            border: 2px solid transparent;
            position: relative;
            // max-width: 80%;
            min-width: 200px;
            max-height: 41px;

            cursor: pointer;
             &:hover {
                 background-color: var(--icon-hover-background-color);
                 color: black;
             }

             .detail-input{
                 border: none;
                 outline: none;
                 color: var(--text-color);
                 opacity: 0.8;
             }

             .asignee-name {
                max-width: 350px ;
                text-overflow: ellipsis;
                margin: 0;
                white-space: nowrap;
                overflow: hidden;
             }
             .ant-picker  {
                border: none;
                background-color: transparent;
                width: 100%;
            }
            
            .ant-picker-input {
            
                input {
                    &::placeholder {
                        color: var(--text-color);
                        font-weight: 500;
                        font-size: 14px;
                    }
                  
                }
            }
            

        }

        .no-icon {
            font-size: 14px;
            font-weight: 500;
        }
    }

    .focus {
       
        .info-detail{
            &:hover{
                background-color: transparent;
                color: inherit
            }
            border: 2px solid var(--btn-primary-color);
            height: 41px;
           
        }
    }
}

.del-assigne-icon {
    cursor: pointer;
    padding: 5px;
    font-size: 22px;
    border-radius: 4px;
    color: rgb(145, 143, 143);
    &:hover {
        background-color: var(--icon-hover-background-color);
    }
}