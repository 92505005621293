:root{
    --title-icon-color: #6d6e6f;
    --title-hover-color: #1e1f21;
}

.mt-session-container {

}


.mt-session-title{
    display: flex;
    align-items: center;
    height: 50px;
    border-bottom: 1px solid transparent;
    gap: 10px;
    position: relative;

    &:hover{
        cursor: move;
    }

    &:hover .session-title-icon{
        display: block;
    }
   

    h3{
        margin: 0;
        font-weight: 600;
    }
    .session-title-icon{
        color: var( --title-icon-color);
        font-size: 18px;
        cursor: pointer;
        width: 28px;
        height: 28px;
        padding: 6px;
        border-radius: 4px;

        &:hover{
            background-color: var(--text-hover-background-color);
            color: var(--title-hover-color);
        }

       
    }

    .session-title-icon-drag{
        @extend .session-title-icon;
        position: absolute;
        left: -24px;
        cursor: move;

        
    }
}

.hasborder{
    border-bottom: 1px solid var(--border-color);
}

.add-task-btn{
    margin-left: -24px;
    margin-right: -24px;
    padding-left: 84px;
    color: var(--text-color);
    font-size: 15px;
    font-weight: 500;
    opacity: 0.8;
    height: var(--session-row-height);
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: var(--white-color);

    &:hover{
        background-color: var(--table-row-hover-color);

    }
}

.edit-section {
    border:  2px solid var(--btn-primary-color);
    border-radius: 6px;
    padding: 3px 5px  6px 5px;
    outline: none;
    font-weight: 500;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 1.17em;
    margin-left: -6px;
}