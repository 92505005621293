:root{
    --text-editor-border-color: #6d6e6f
}

.TextEdior-wrapper {
    border: 1px solid transparent;
    border-radius: 6px;
    padding: 4px 12px;
    margin: 0 -12px;

    .public-DraftEditorPlaceholder-inner{
        color: var(--text-color);
        font-size: 16px;
        font-weight: 500;
    }

    &:hover {
        border: 1px solid var(--border-hover-color);
        cursor: text;
    }


    .textEditor-style{
        display: flex;
        gap: 15px;
        min-height: 26px;

        .textEdit-icon {
            font-size: 26px;
            border-radius: 6px;
            padding: 4px;
            opacity: 0.8;

            &:hover {
                background-color: var(--detail-button-hover-color);
                color: black;
                cursor: pointer;
            }
        }

        .active{
            color: var( --btn-primary-color);
            background-color: var(--textedit-active-bgcolor);
        }
    }
}

.TextEditor-focus {
    border: 1px solid var(--text-editor-border-color);

    &:hover {
        border: 1px solid var(--text-editor-border-color);
    }
}

.description {
    padding: 10px 20px;
    min-height: 50px;

    p {
        margin: 0;
    }

    &:hover {
        border-radius: 6px;
        border: 1px solid var(--border-hover-color);
    }
}


