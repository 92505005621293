.delete-task-container {
  background-color: var(--white-color);
  height: 100vh;
  margin: -24px;
  padding: 0 24px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}
.dt-task-header-container {
  margin-top: 60px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  flex: 0.5;
  align-items: flex-end;
}
.delete-task-header {
  margin-bottom: 10px;
  cursor: pointer;
  width: 100px;
  font-weight: bold;
  
}

.delete-task-content-container {
  margin-top: 10px;
  flex: 9;
}
.dt-task-container-header {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0 4px;
  .dt-task-row-header-nametask {
    width: 100%;
    height: 100%;
    padding-left: 62px;
    border-right: 1px solid;
    border-top: 1px solid;
    border-bottom: 1px solid;
    display: flex;
    align-items: center;
    flex: 6.5;
    border-color: #edeae9;
  }
  .dt-task-row-header-assigntask {
    width: 100%;
    height: 100%;
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-right: 1px solid;
    border-color: #edeae9;
    display: flex;
    align-items: center;
    flex: 1.5;
  }
  .dt-task-row-header-duedatetask {
    width: 100%;
    height: 100%;
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-right: 1px solid;
    border-color: #edeae9;
    display: flex;
    align-items: center;
    flex: 1.5;
  }
  .dt-task-row-header-blanktask {
    width: 100%;
    height: 100%;
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-color: #edeae9;
    flex: 0.5;
  }
}
.dt-task-content-row {
  border-bottom: 1px solid;
  border-color: #edeae9;
  display: flex;
  flex-direction: row;
  flex: 1;
  // z-index: -1000;
  align-items: center;
  .delete-task-row-nametask {
    display: flex;
    height: 100%;
    width: 100%;
    margin: 0px;
    border-right: 1px solid;
    border-color: #edeae9;
    flex: 6;
    // padding-left: 62px;
    align-items: center;
    width: 100%;
    justify-content: center;
    align-content: center;

    .delete-task-row-nametask-title {
      display: flex;
      justify-content: center;
      flex: 7.5;
      width: 100%;
      height: 100%;
      align-items: center;
      .row-title-check-icon {
        display: flex;
        font-size: 20px;
      }
      .delete-task-row-input {
        width: 100%;
        height: 100%;
        font-size: 16px;
        padding: 0px 4px;
        border: none;
      }
    }
    .delete-task-row-nametask-item {
      flex: 2;
      display: flex;
      align-items: center;
      flex-direction: row;
      margin-left: 5px;
      // z-index: -1000;
      // background-color: white;
      color: white;
      cursor: pointer;
      .row-item-detail {
        align-items: center;
        margin: auto;
        display: flex;
        font-weight: bold;
      }
    }
    .delete-task-row-nametask-icon {
      flex: 0.5;
      display: flex;
      padding-right: 20px;
      justify-content: center;
      border-right: 1px solid;
      height: 100%;
      border-color: #edeae9;
      color:red;
      font-weight: bold;
      cursor: pointer;
      .TrashIcon {
        display: block;
      }
      .UndoIcon {
        display: none;
      }
    }
    .delete-task-row-nametask-icon:hover {
      .TrashIcon {
        display: none;
      }
      .UndoIcon {
        display: block;
      }
    }
  }
  .delete-task-row-assigntask {
    width: 100%;
    height: 100%;
    border-right: 1px solid;
    border-color: #edeae9;
    flex: 1.5;
    display: flex;
    align-items: center;
    justify-content: center;
    .delete-task-row-section-assigntask-icon {
      display: flex;
      margin-left: 10px;
      height: 100%;
      align-items: center;
      min-width: 20px;
    }
    .delete-task-row-section-assigntask-name {
      flex: 1;
      width: 50px;
      height: 100%;
      display: flex;
      margin-left: 10px;
      align-items: center;
      p {
        text-overflow: ellipsis;
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
  .delete-task-row-duedatetask {
    height: 100%;
    width: 100%;
    flex: 1.5;
    border-right: 1px solid;
    border-color: #edeae9;
  }
  .delete-task-row-blanktask {
    height: 100%;
    width: 100%;
    flex: 0.5;
  }
}
.delete-task-title {
  border-bottom: 1px solid;
  border-color: #edeae9;
  font-size: 16px;
}
.dt-task-content-row:hover {
  .delete-task-row-nametask-item {
    // z-index: 1000;
    color: black;
  }
  .delete-task-row-nametask-title {
    .delete-task-row-input {
      border: 1px solid;
    }
  }
  background-color: #ecececce;
}
.delete-task-row-section-nametask {
  flex: 1;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
}
.delete-task-row-section-assigntask {
  flex: 1;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.delete-task-row-section-duedatetask {
  flex: 1;
  display: flex;
  width: 100%;
  height: 100%;
  .labelDueOn{
    padding-left: 20px;
  }
}

.mytask-content-container-deletetask{
  transition: ease 0.2s;
  right: 0;
  padding: 0 24px;
  position: absolute;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  top: calc(var( --session-row-height) + var(--page-header-height) + var(--my-task-header-height) + 50px);
}
