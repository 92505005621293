.lock-con {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    
    .lock-icon {
        font-size: 6rem;
    }

    h2{
        font-size: 2rem;
        color: var(--text-color) !important;
    }
}
