.mt-btn-container {
    display: flex;
    height: 28px;
    background-color: var(--btn-primary-color);
    max-width: fit-content;
    border-radius: 6px;
    overflow: hidden;


    .mt-add-task{
        display: flex;
        align-items: center;
        padding: 0 10px;
        gap: 8px;
        cursor: pointer;

        .mt-btn-title{
        color: var(--white-color);
        
        }

        &:hover{
            background-color: var(--btn-primary-color-hover);
        }

    }
}

.mt-down{
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 4px;
    border-left: 1.5px solid var(--white-color);
    cursor: pointer;

    &:hover{
        background-color: var(--btn-primary-color-hover);
    }

    .mt-btn-icon{
        font-size: 20px;
    }
}
.mt-btn-icon{
    color: var(--white-color);
    font-size: 14px;
}