.subtaskrow-container{
    margin: 0 -24px;
    cursor: pointer;

    &:hover {
        background-color: var(--table-row-hover-color);
    }

    &:hover .subtaskrow-wrapper .dragIcon{
        display: block;
    }

    &:hover .subtaskrow-wrapper .subtaskrow-detail-icon .st-row-icon{
        display: block;
    }

    

    .subtaskrow-wrapper{
        position: relative;
        display: flex;
        min-height: var(--subtask-row-height);
        margin: 0 24px;
        gap: 10px;
        border-bottom: 1px solid var(--border-color);
    

       


        .dragIcon{
            position: absolute;
            top: 7px;
            left: -22px;
            font-size: 18px;
            display: none;

        }
    
        .subtaskrow-title{
            display: flex;
            align-items: center;
            gap: 8px;
            max-height: var(--subtask-row-height);
    
            .subtaskrow-checkicon{
                font-size: 18px;
                position: relative;
            }
    
            
        }
    
        .textarea-container{
          flex: 1;
          display: flex;
          align-items: center;
            textarea{
                    flex: 1;
                        resize: none;
                        min-height: var(--subtask-row-height);
                        background-color: transparent;
                        border: none;
                        outline: none;
                        font-size: 16px;
                        padding-top: 2px;
                        
                        &::-webkit-scrollbar{
                            width: 0;
                        }
                    }
        }
    
        .subtaskrow-detail-icon{
    
            display: flex;
            gap: 8px;
            font-size: 16px;
            align-items: center;
            max-height: var( --subtask-row-height);
            width: 80px;
            opacity: 0.8;
            justify-content: flex-end;
    
    
            .st-row-icon{
                cursor: pointer;
                &:hover{
                    color: black;
                }

                display: none;
            }
    
            .chat-icon-wrapper{
                display: flex;
                align-items: center;
                padding: 4px 8px;
                border-radius: 6px;
                justify-content: flex-end;
    
                &:hover{
                    background-color: var( --table-row-hover-color);
                }
            }
    
        }

    }
  

   
}

.selected {
    background-color: var(--select-row-color);

    .subtaskrow-wrapper .subtaskrow-detail-icon .st-row-icon{
        display: block;
    }

    &:hover {
        background-color: var(--select-row-color);
    }
}