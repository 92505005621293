.mt-inbox {
  /* margin-top: 48px; */
  /* background-color: #ccc; */
  display: flex;
  /* width: 50%; */
  height: 84vh;
}
.project-name-heading {
  display: flex;
  align-items: center;
}
.project-name-heading .icon {
  color: #5da283;
  font-size: 7px;
}
.project-name-heading .title {
  font-size: 16px;
  margin-left: 5px;
}
.project-name {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.project-name .icon {
  font-size: 18px;
  margin-bottom: 5px;
}
.project-name .title {
  font-size: 23px;
  font-weight: 500;
  margin-bottom: 13px;
}
.user-asign {
  display: flex;
  width: 622px;
}
.avatar {
  display: flex;
  align-items: center;
  height: 30px;
  background-color: #ffffff;
  border-radius: 50%;
  min-width: 30px;
  justify-content: center;
  font-size: 10px;
  position: relative;
  color: #606060;
}
.avatar p {
  margin: 0;
}
.task-content {
  margin-left: 10px;
}
.task-content .user-name {
  font-weight: 500;
  margin-bottom: 3px;
}
.task-content .asign-name {
  color: #3f6ac4;
  font-weight: 400;
  margin-bottom: 3px;
}
/* .asign-list {
  height: 80vh;
  overflow-y: auto;
  width: 100%;
} */
.asign-list-archive {
  height: 87vh;
  overflow-y: auto;
  width: 45%;
}
.asign-wrap {
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  padding-bottom: 6px;
}
.asign-wrap .icon-like {
  margin-right: 69px;
  font-size: 16px;
}
.icon-like {
  display: none;
}
.asign-item {
  margin-top: 20px;
}
.asign-item:hover {
  cursor: pointer;
}
.asign-item:hover .icon-like {
  display: block;
  cursor: pointer;
}
.asign-item {
  margin-top: 10px;
  position: relative;
  padding-bottom: 10px;
}
.ant-tabs-content-holder {
  flex: auto;
  min-width: 0;
  min-height: 0;
  margin-left: -24px;
}
.Archive-list {
  position: absolute;
  right: 0;
  top: 0;
  margin: -6px 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 0 0 1px #edeae9, 0 1px 4px 0 rgb(109 110 111 / 8%);
  font-size: 16px;
}
.Archive-list span {
  padding: 8px 10px;
  display: inline-flex;
}
.Archive-list {
  display: none;
}
.asign-item:hover .Archive-list {
  display: block;
}
.message {
  position: absolute;
  right: -7px;
  bottom: -11px;
  font-size: 20px;
  width: 24px;
  height: 24px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  border-radius: 50%;
}
.screenDetailInbox {
  width: 55%;
}
span.icon-message {
  position: relative;
  top: -2px;
}

.mt-inbox .task-content body {
  background: transparent !important;
  height: auto;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: auto;
  max-width: 622px!important; */
}
.assign-project-name {
  padding-bottom: 2px;
}
.assign-project-name span {
  margin-left: 25px;
  font-weight: 600;
}
.assign-project-name .row-item-icon {
  position: absolute;
}
.comment-date {
  font-size: 9px;
}
.assign-project-name .completed {
  color: #5da283;
}
.active-item-ibox {
  padding: 10px 10px 10px 26px;
  border-bottom: 1px solid #ccc;
}
svg.comment-icon {
  color: #406ac4;
  margin-top: 4px;
  font-size: 18px;
}
.archive-all {
  width: 45%;
}
.archive-all-notification {
  position: relative;
  top: -6px;
  left: 0;
}
button.btn-archive {
  border: 0;
  background-color: #dbe0fd;
  color: #3f6ac4;
  padding: 4px 8px;
  font-weight: 500;
  border-radius: 10px;
  margin-left: 10px;
}
svg.icon-filter {
  font-size: 16px;
  position: relative;
  top: 3px;
}
button.btn-archive > * {
  margin-right: 2px;
}
p.noData {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 38px;
}
.ant-space-item:hover button.btn-archive {
  /* border-radius: 0 !important; */
  cursor: pointer;
  /* padding: 1px 8px 4px 8px; */
}
svg.icon-User {
  margin-left: 3px;
  margin-right: -14px;
}
span.name-item {
  font-size: 14px;
  font-weight: 400;
}
.project-name-wrap {
  display: flex;
  align-items: center;
}
.show-more,
.show-less {
  color: #3f6ac4;
  font-weight: 500;
}
.icon-like-wrap {
  display: flex;
  width: 68px;
  justify-content: space-evenly;
  align-items: center;
  height: 40px;
  margin-right: 30px;
}
.like-amount {
  margin-right: 5px;
  margin-top: 6px;
}
p.show-more-project-item {
  margin: 0;
}
.comment-name-read-more {
  word-break: break-word;
  margin: 0;

  /* -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box */
}

.comment-name-read-more p{
  margin: 0;
}
.comment-name {
  word-break: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box
}
button.btn-readmore-inbox {
  padding: 1px 6px;
  border-radius: 10px;
  border: 1px solid #ccc;
  font-size: 11px;
  margin-bottom: 8px;
  cursor: pointer;
}
.comment-name p {
  margin: 0;
}
li.ant-dropdown-menu-item {
  /* display: flex; */
  /* text-align: center; */
}
span.ant-dropdown-menu-title-content {
  display: flex;
  justify-content: center;
}

svg.icon-ckeck-inbox.ant-dropdown-menu-item-icon {
  color: green;
  position: absolute;
  left: 34px;
}
@media screen and (max-width:1358px) {
  .mt-inbox {
    height: 76vh;
}
#asign-list{
  height: 73vh !important;
}
.asign-list-archive {
  height: 81vh !important;
}
}

@media screen and (min-width:1358px) and (max-width:1550px) {
  .mt-inbox {
    height: 76vh;
}
#asign-list{
  height: 73vh !important;
}
.asign-list-archive {
  height: 81vh !important;
}
  
}
.wrap-content p {
  margin: 0;
  max-width: 500px;
}

.task-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.starred-inbox {
  margin-right: 44px;
  font-size: 20px;
  color: #dc0;
}
.aiFillStar-icon:hover{
  color: #000;
}
.active-item-ibox:hover{
  background: rgba(55, 23, 23, 0.03);
}
span.general-icon:hover {
  color: #000;
}
.icon-like-and-new-inbox{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}