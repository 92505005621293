.subtask-container {
    margin-top: 8px;
    padding: 0 24px;
    padding-bottom: 40px;

    p{
        margin: 0;
        padding-bottom: 1em;
        border-bottom: 1px solid var(--border-color);
        font-weight: 500;
    }

    .loadmore {
        color: var(--btn-primary-color);
        margin-top: 10px;
        width: max-content;

        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }


    .subtask-btn{
        padding: 3px 6px;
        border-radius: 6px;
        border: 1px solid var(--border-hover-color);
        background-color: transparent;
        display: flex;
        align-items: center;
        gap: 6px;
        cursor: pointer;
        transition: ease 0.3s;
        margin-top: 10px;


        span{
            font-weight: 500;
        }

        &:hover{
            background-color: var(--table-row-hover-color);
            color: black;
            transition: ease 0.3s;
        }

    }
}
