.comment-container {
  display: flex;
  padding: 8px 24px;
  gap: 10px;
  background-color: var(--footer-bg-color);
  width: 100%;

  .comment-ava {
    img {
      height: 32px;
      width: 32px;
      border-radius: 50%;
    }
  }

  .comment-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 95%;
    overflow-wrap: break-word;

    .comment-user-name {
      font-size: 15px;
      font-weight: 500;

      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .cmt-name-time {
        display: flex;
        gap: 10px;
        p {
          line-height: 1;
          margin: 0;
          font-size: 12px;
          color: (--text-color);
        }
      }

      .comment-icons {
        display: flex;
        align-items: center;
        gap: 10px;
        position: relative;

        .comment-icon {
          cursor: pointer;
          font-size: 28px;
          padding: 6px;
          border-radius: 6px;
          display: flex;
          align-items: center;

          &:hover {
            background-color: var(--icon-hover-background-color);
          }
        }
        .liked {
          color: var(--like-btn-color);
        }

        .comment-options {
          position: absolute;
          display: flex;
          flex-direction: column;
          width: 150px;
          bottom: -75px;
          right: 0;
          background-color: white;
          border-radius: 4px;
          padding: 6px 0;
          border: 1px solid var(--border-color);
          z-index: 999;

          .option-item {
            font-size: 14px;
            display: flex;
            align-items: center;
            padding: 4px 8px;
            cursor: pointer;

            &:hover {
              background-color: var(--footer-bg-color);
            }
          }

          .del-item {
            color: #c92e54;
          }
        }
      }

      span {
        line-height: 15px;
        color: black;
      }
    }

    .comment-content {
      margin: 0;
      width: 100%;
      white-space: inherit;

      P,
      h1,
      h2,
      h3,
      h4,
      h5 {
        margin: 0;
      }
      img {
        max-width: 100px;
        border-radius: 6px;
      }
    }
    .content-asana {
      white-space: pre-line;
    }

    .cmt-file-container {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      margin-top: 10px;
    }

    .comment-file {
      img {
        max-width: 100px;
        border-radius: 6px;
      }
    }
  }
}
.comment-pintotop{
  background-color: #fff6ee;
  // border: 1px solid #dfdfdf;
}
.comment_boderleft{
  border-left: 4px solid #f1bd6c;
  position: relative;
  right: 24px;
}
